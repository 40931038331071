import { Box, Grid } from "@mui/material";
import Card from "components/card";
import DefaultBtnModel from "components/defaultBtnModel/DefaultBtnModel";
import AppSnackbar from "components/snakbar";
import { SnackbarState } from "models/user-models";
import { ExaminationModal, WizardOptionModal, WizardProps, wizardDisplayModal, wizardIterationModal } from "models/wizard/wizard-modals";
import React, { useEffect, useState } from "react";

const WizardManagement: React.FC<WizardProps> = ({ duration, setDuration, durationType, setDurationType, systemList, complaintList, durationList,
    examinationList, impressionList, treatmentList, updateReportList, setIsCompleted, complaintRemark, setComplaintRemark,subComplaintRemark,setSubComplaintRemark, impressionRemark, setImpressionRemark, examinationRemark, setExaminationRemark,data,setData }) => {
    const [selectedOptionList, setSelectedOptionList] = useState<WizardOptionModal[]>([]);
    const [selectedSubExams, setSelectedSubExams] = useState<wizardDisplayModal[]>([]);
    // const [selectedOptionList, setSelectedOptionList] = useState<string[]>([]);
    const [displayList, setDisplayList] = useState<wizardDisplayModal[]>([])
    const [currentIteration] = useState<wizardIterationModal>({ iterationName: "Systems", iterationState: true })
    const [page, setPage] = useState<number>(1)
    const [addMore, setAddMore] = useState<boolean>(false)
    const [examId, setExamId] = useState<string>("")
    const [subExams, setSubExams] = useState<string[]>([])
    const [selectedButton, setSelectedButton] = useState(null);
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'info', 
    });

    const handleButtonClick = (id:any) => {
      setSelectedButton(id);
    };
  
    const [defaultBtn,setDefaultBtn] = useState([{
        id:"temp",
        desc:"Temperature",
        units:["a","b"]
    },
    {
        id:"bp",
        desc:"Blood Pressure",
        units:["a","b"]
    },
    {
        id:"hr",
        desc:"Heart Rate",
        units:["a","b"]
    },
    {
        id:"wei",
        desc:"Weight",
        units:["a","b"]
    },
    {
        id:"hei",
        desc:"Height",
        units:["a","b"]
    },
    {
        id:"bmi",
        desc:"Body Mass Index",
        units:["a","b"]
    }
])


    const [displayObj, setDisplayObj] = useState({
        optionNumber: 1,
        heading: "Systems:",
        subHeading: ""
    })
    const [selectedValue, setSelectedValue] = useState({
        system: "",
        complainet: "",
        examination: "",
        impression: ""
    })

    const handleComplaintClick = (item: string) => {
        let page_name = ""
        if (page === 1) {
            page_name = "Systems"

            setSelectedValue(prevState => ({
                ...prevState,
                system: item
            }));
        }
        else if (page === 2) {
            page_name = "Complaints"
            setSelectedValue(prevState => ({
                ...prevState,
                complainet: item
            }));
        }
        else if (page === 3) {
            page_name = "Examinations"
            setSelectedValue(prevState => ({
                ...prevState,
                examination: item
            }));
        }
        else if (page === 4) {
            page_name = "Impressions"
            setSelectedValue(prevState => ({
                ...prevState,
                impression: item
            }));
        }
        else if (page === 5) {
            page_name = "Treatment Plans"
        }
        if (page === 1) {
            const x = selectedOptionList.filter((selectedItem) => selectedItem.page_name !== "Systems")
            if (selectedOptionList.some(option => option.id === item)) {
                setSelectedOptionList(x.filter((selectedItem) => selectedItem.id !== item));
            } else {
                setSelectedOptionList([...x, { id: item, page_name: page_name }])
            }
        }
        else if (page === 2) {
            const x = selectedOptionList.filter((selectedItem) => selectedItem.page_name !== "Complaints")
            if (selectedOptionList.some(option => option.id === item && option.page_name === "Sub Complaints")) {
                alert("You have selected this as a sub complaint.")
            }
            else if (selectedOptionList.some(option => option.id === item)) {
                setSelectedOptionList(x.filter((selectedItem) => selectedItem.id !== item));
            } else {
                setSelectedOptionList([...x, { id: item, page_name: page_name }]);
            }
        }
        else if (page === 3) {
            if (selectedOptionList.some(option => option.id === item)) {
                setSelectedOptionList(selectedOptionList.filter((selectedItem) => selectedItem.id !== item));
                setSelectedSubExams([])
            } else {
                setSelectedOptionList([...selectedOptionList, { id: item, page_name: page_name }]);
                const exam: ExaminationModal = examinationList.filter(x => x._id === item)[0]
                if (exam.scroll_downs) {
                    setSubExams(exam.scroll_downs)
                    setExamId(exam._id)
                }
            }
        }
        else {
            if (selectedOptionList.some(option => option.id === item)) {
                setSelectedOptionList(selectedOptionList.filter((selectedItem) => selectedItem.id !== item));
            } else {
                setSelectedOptionList([...selectedOptionList, { id: item, page_name: page_name }]);
            }
        }
    };

    const handleSubComplaintClick = (item: string) => {
        if (selectedOptionList.some(option => option.id === item && option.page_name === "Complaints")) {
            alert("You have selected this as a main complaint.")
        } else if (selectedOptionList.some(option => option.id === item)) {
            setSelectedOptionList(selectedOptionList.filter((selectedItem) => selectedItem.id !== item));

        } else {
            setSelectedOptionList([...selectedOptionList, { id: item, page_name: "Sub Complaints" }]);
        }
    };

    const handleSubExamClick = (item: string) => {
        if (selectedSubExams.some(option => option.description === item)) {
            setSelectedSubExams(selectedSubExams.filter((selectedItem) => selectedItem.description !== item));

        } else {
            setSelectedSubExams([...selectedSubExams, { id: examId, description: item }]);
        }
    };

 
    useEffect(() => {
        updateReportList(selectedOptionList, selectedSubExams)
    }, [selectedOptionList, selectedSubExams,data])

    const handleNext = () => {
        if (page === 1) {
            setToPage(complaintList, 2, "What is the main complaint?", "", selectedValue.system)
            setPage(2)
        }
        else if (page === 2) {
            setToPage(examinationList, 3, "On Examinations:", "", selectedValue.complainet)
            setPage(3)
        }
        else if (page === 3) {
            setToPage(impressionList, 4, "Impressions", "", selectedValue.examination)
            setPage(4)
        }
        else if (page === 4) {
            setToPage(treatmentList, 5, "Treatment Plans", "", selectedValue.impression)
            setPage(5)
        }
        else if (page === 5) {
            setIsCompleted(true)
        }
    }

    const handlePrevious = () => {
        // alert(page)
        if (page === 5) {
            setToPage(impressionList, 4, "Impressions", "", selectedValue.impression)
            setPage(4)
        }
        if (page === 4) {
            setToPage(examinationList, 3, "On Examinations:", "", selectedValue.examination)
            setPage(3)
        }
        if (page === 3) {
            setToPage(complaintList, 2, "What is the main complaint?", "", selectedValue.complainet)
            setPage(2)
        }
        if (page === 2) {
            setToPage(systemList, 1, "Systems", "", "system")
            setPage(1)
        }
    }

    const setToPage = (list: any, optionNumber: number, heading: string, subHeading: string, selectedId?: string) => {
        let tempComplaintList: wizardDisplayModal[] = []
        let tempDisplayArray: wizardDisplayModal[] = handleDisplayListConverstion(list)
        tempComplaintList.push.apply(tempComplaintList, tempDisplayArray)
        let filteredList = tempComplaintList?.filter(obj => obj.system_id?.includes(selectedValue.system));
        setDisplayList(selectedId === "system" ? tempComplaintList : filteredList)
        setDisplayObj({
            optionNumber: optionNumber,
            heading: heading,
            subHeading: subHeading
        })
    }

    function handleDisplayListConverstion(tempList: any) {
        let tempDisplayArray: wizardDisplayModal[] = []
        if (tempList && tempList.length > 0) {
            for (let index = 0; index < tempList.length; index++) {
                let tempDisplayObj: wizardDisplayModal = Object()
                const complaint = tempList[index];
                tempDisplayObj = {
                    id: complaint._id,
                    description: complaint.description,
                    system_id: complaint.system_id
                }
                tempDisplayArray.push(tempDisplayObj)
            }
            return tempDisplayArray
        }
    }


    useEffect(() => {
        if (systemList && systemList.length > 0) {
            const initialDisplayArray: wizardDisplayModal[] = handleDisplayListConverstion(systemList)
            setDisplayList(initialDisplayArray)
        }
    }, [systemList])

    // const displayObj = displayText.find(x => x.isActive)
    const onRemoveClick = (description: string) => {
        const alreadExists = data.some((e) => e.description ===  description);
        if(alreadExists){

            setData((prevData) => prevData.filter((item) => item.description !== description));
            // if(description === "Weight" || description === "Height"){
            //     setData((prevData) => prevData.filter((item) => item.description !== "Body Mass Index"));
            // }     
        }
   
    }

 
    const onSubmitClick = (description: string, value: { inputValue: string, unitValue: string }) => {
        const matched = data.find((e) => e.description === description);
      
        if (!matched && description !== "Body Mass Index") {
            setData((prevData) => [
              ...prevData,
              { description, value },
            ]);
          }
        
      


        const weight = data.find((e) => e.description === "Weight");
        const height = data.find((e) => e.description === "Height");
        const weightExists = !!weight;
        const heightExists = !!height;
        

        if (description === "Body Mass Index") {
            if (!weight || !height) {
                setSnackbar({ open: true, message: "Weight and Height are required", severity: 'error' });
                return;
            }
        }

        

     
        if (description === "Body Mass Index") {
            if (weight && height) {
             

                // Convert weight to kilograms if needed
                let weightValue = parseFloat(weight.value.inputValue);
                if (weight.value.unitValue === "Pounds") {
                    weightValue = weightValue * 0.453592; // Convert pounds to kilograms
                }
        
                // Convert height to meters if needed
                let heightValue = parseFloat(height.value.inputValue);
                if (height.value.unitValue === "Feets") {
                    heightValue = heightValue * 0.3048; // Convert feet to meters
                } // No need to convert if it's already in meters
        
                const bmiValue = weightValue / (heightValue * heightValue);
                
                // Ensure that both weight and height exist before updating the data
                if (!matched && weightExists && heightExists) {
                    setData((prevData) => [
                        ...prevData,
                        { description, value: { inputValue: bmiValue.toFixed(4), unitValue: "BMI" } },
                    ]);
                }
                return;
            }
        }
        

       
      };
        React.useEffect(()=>{
            localStorage.setItem("data",JSON.stringify(data))
        },[data])
    
    return (
        <div className={page === 1 ? "wizardBody" : "wizard"}>
            <h1 className="mt-3 text-[18px] text-black font-semibold w-[100%] sm:w-[70%] text-left">{displayObj && displayObj.heading ? displayObj.heading : ""}</h1>
            {/* <h1 className="mt-3 text-[18px] text-black font-semibold w-[100%] sm:w-[70%] text-left">{displayObj && displayObj.optionNumber ? displayObj.optionNumber : ""} - {displayObj && displayObj.heading ? displayObj.heading : ""}</h1> */}

            <p className=" mt-2 text-[20px] text-black w-[100%] sm:w-[100%]">{displayObj && displayObj.subHeading ? displayObj.subHeading : ""}</p>
            <div className="flex flex-wrap mt-5 wizard no-scrollbar justify-center" style={{ height: page === 2 ? "73%" : "80%", overflowY: "auto" }}>
            <Grid container spacing={1}>
                {defaultBtn.map((e,i)=>{
                     if (page === 3) {
                        const isInData = data.some((item) => item.description === e.desc);
                        return(
                            <>
<Grid key={i} item xs={12} sm={6} md={2} >
    <Box   onClick={() => handleButtonClick(i)}>
        
          <DefaultBtnModel desc={e.desc}  data={data}    isSelected={selectedButton === i}
          onSubmit={onSubmitClick} onRemove={onRemoveClick} isInData={isInData}
              />
              </Box>
            </Grid>
                            </>
                        )
                     }
                })}
            
                {displayList && displayList.length > 0 ? (
                    displayList.map((x, i) => {
                        if (page === 2) {
                            return (
                                <Grid item xs={12} sm={6} md={3} >
                                <button
                                    key={i + 2000}
                                    onClick={() => handleComplaintClick(x.id)}
                                    className={`${selectedOptionList.find((c) => c.id === x.id && c.page_name === "Complaints") ? 'buttonSelected' : ''
                                        } viewButton flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 m-1 mr-3`}
                                >
                                    {x.description}
                                </button>
                                </Grid>
                            )
                        }
                        else {
                            return (
                                <Grid item xs={12} sm={6} md={3} >
                                <button
                                    key={i + 2000}
                                    onClick={() => {

                                        handleComplaintClick(x.id)
                                    }}
                                    className={`${selectedOptionList.find((c) => c.id === x.id) ? 'buttonSelected' : ''
                                        } viewButton flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 m-1 mr-3`}
                                >
                                    {x.description}
                                </button>
                                </Grid>
                            )
                        }
                    })
                ) : (
                    <p className="mt-3 text-[16px] text-red w-[100%] sm:w-[70%]">There are no further sub-classes!</p>
                )}
                   </Grid>
            </div>

            {page === 2 && addMore && <>
                <div className="wizardDialog">
                    <div className="wizard-dialog-body">
                        <span className="wizard-dialog-close" onClick={() => setAddMore(false)}>&times;</span>
                        <div className="wizardBody">
                            <div className="flex flex-wrap mt-5 p-5">
                                <h1 className="mb-3 text-[18px] text-black font-semibold w-[100%] sm:w-[100%] text-left">{" - Other Symptoms"}</h1>
                                {displayList && displayList.length > 0 ? (
                                    displayList.map((x, i) => (
                                        <button
                                            key={i + 2000}
                                            onClick={() => handleSubComplaintClick(x.id)}
                                            className={`${selectedOptionList.find((c) => c.id === x.id && c.page_name === "Sub Complaints") ? 'buttonSelected' : ''
                                                } viewButton flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 m-1 mr-3`}
                                        >
                                            {x.description}
                                        </button>
                                    ))
                                ) : (
                                    <p className="mt-3 text-[16px] text-red w-[100%] sm:w-[70%]">There are no further sub-classes!</p>
                                )}
                                
                            </div>
                            <div className="flex flex-col mt-5 p-5">
                    <label htmlFor="dursation" >Remarks</label>
                    <input
                        type="text"
                        value={subComplaintRemark}
                        onChange={(e) => setSubComplaintRemark(e.target.value)}
                        placeholder="Remarks for complaints"
                        className="mt-2 p-2 border rounded w-[95%]"
                    />
                </div>
                            <div className="flex flex-col mt-auto">
                                <div className="flex flex-row justify-end">
                                    <button onClick={() => {
                                        setAddMore(false)
                                    }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 mr-3`}>
                                        Done
                                    </button>
                                </div>
                            </div>
                         
               
           
                        </div>
                    </div>
                </div>

            </>
            }

            {page === 3 && subExams && subExams.length ? <>
                <div className="wizardDialog">
                    <div className="wizard-dialog-body">
                        <span className="wizard-dialog-close" onClick={() => {
                            setSubExams([])
                            setExamId("")
                        }}>&times;</span>
                        <div className="wizardBody">
                            <div className="flex flex-wrap mt-5 p-5">
                                <h1 className="mb-3 text-[18px] text-black font-semibold w-[100%] sm:w-[100%] text-left">{" - Examination Condition"}</h1>
                                {subExams && subExams.length > 0 ? (
                                    subExams.map((x, i) => (
                                        <button
                                            key={i + 2000}
                                            onClick={() => handleSubExamClick(x)}
                                            className={`${selectedSubExams.find((c) => c.description === x) ? 'buttonSelected' : ''
                                                } viewButton flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 m-1 mr-3`}
                                        >
                                            {x}
                                        </button>
                                    ))
                                ) : (
                                    <p className="mt-3 text-[16px] text-red w-[100%] sm:w-[70%]">There are no further sub-classes!</p>
                                )}
                            </div>
                            <div className="flex flex-col mt-auto">
                                <div className="flex flex-row justify-end">
                                    <button onClick={() => {
                                        setSubExams([])
                                        setExamId("")
                                    }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200 mr-3`}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <></>
            }
          
            {page === 2 &&
                <div className="flex flex-col ">
                    <label htmlFor="duration" className="mb-1">What is the duration of the main complaint?</label>
                    <div className="flex flex-row mt-5 justify-between"> {/* Added justify-between class */}
                        <div className="flex flex-row"> {/* Wrapped input and select elements */}
                            <input
                                id="duration"
                                placeholder=""
                                className="h-12 rounded-xl border bg-white/0 p-3 text-base outline-none"
                                type="number"
                                min={1}
                                max={60}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(parseInt(e.target.value))
                                }}
                            />
                            <select className="h-12 rounded-xl border bg-white/0 p-3 text-base outline-none"
                                value={durationType}
                                onChange={(e) => {
                                    setDurationType(e.target.value)
                                }}
                            >
                                <option>Seconds</option>
                                <option>Minutes</option>
                                <option>Days</option>
                                <option>Weeks</option>
                                <option>Months</option>
                                <option>Years</option>
                            </select>
                        </div>
                        <button onClick={() => {
                            setAddMore(true)
                        }} style={{ marginRight: "1%" }} className={`flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200`}>{/* Removed ml-3 class */}
                            Add More Symptoms
                        </button>
                    </div>
                </div>
            }
              {page === 2 && (
                <div className="mt-5">
                    <label htmlFor="dursation" >Remarks</label>
                    <input
                        type="text"
                        value={complaintRemark}
                        onChange={(e) => setComplaintRemark(e.target.value)}
                        placeholder="Remarks for complaints"
                        className="mt-2 p-2 border rounded w-[95%]"
                    />
                </div>
            )}

            {page === 3 && (
                <div>
                    <label htmlFor="dursation" className="">Remarks</label>
                    <input
                        type="text"
                        value={examinationRemark}
                        onChange={(e) => setExaminationRemark(e.target.value)}
                        placeholder="Remarks for Examinations"
                        className="mt-2 p-2 border rounded w-[95%]"
                    />
                </div>
            )}
            {page === 4 && (
                <div>
                    <label htmlFor="dursation" className="">Remarks</label>
                    <input
                        type="text"
                        value={impressionRemark}
                        onChange={(e) => setImpressionRemark(e.target.value)}
                        placeholder="Remarks for Impressions"
                        className="mt-2 p-2 border rounded w-[95%]"
                    />
                </div>
            )}
            <div className="flex items-center mt-5 justify-end wizardNavigationButtons">
                <button onClick={handlePrevious} className={`w-20 flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3}`}>
                    Previous
                </button>
                {currentIteration && currentIteration.iterationName === "Systems" && currentIteration.iterationState && <button style={{ marginLeft: "1%" }} disabled={selectedOptionList && selectedOptionList.length <= 0} onClick={handleNext} className={`buttonSelected w-20 flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3 ${selectedOptionList && selectedOptionList.length <= 0 ? "disabledButton" : ""}`}>
                    Next
                </button>}
                {/* <button disabled={(selectedOptionList && selectedOptionList.length <= 0 && displayList && displayList.length > 0)} onClick={handleFinish} className={`${currentIteration && (currentIteration.iterationName === "Treatment") ? "finishButton w-40" : "buttonSelected"} flex items-center text-sm hover:cursor-pointer bg-lightPrimary p-3 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10" linear justify-center rounded-lg font-bold transition duration-200 mr-3 ${(selectedOptionList && selectedOptionList.length <= 0 && displayList && displayList.length > 0) ? "disabledButton" : ""}`}>
                    {currentIteration && currentIteration.iterationName !== "Treatment" ? `Proceed with ${currentIteration.iterationName}` : "Finish"}
                </button> */}
            </div >
            <AppSnackbar
                isOpen={snackbar.open}
                title={snackbar.message}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
            />
        </div>
    );
}

export default WizardManagement;